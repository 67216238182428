/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@vds/icons';
import styled from 'styled-components';
import { showLoader, hideLoader } from './common/loader/actions';
import Link from './common/Link';
import { MethodType } from '../constants/common';
import callingApi from '../services/apiService';
import { apiUrl } from '../constants/api-urls';
import { dataTrack, dataTrackwithDetail } from '../utils/vzdl';
import { linkVzTag } from '../utils/unifiedVzTag';
import { logMetrics } from '../services/logService';
import { setStreetGeoError } from './address-lookup/actions';

const GeoIcon = styled.div`
  cursor: pointer;
`;
const GeoLocation = styled.div`
  &:focus {
    outline-style: dotted;
    outline-color: ${(props) => props.color} !important;
  }
  &:active:focus {
    outline-style: none;
  }
  p {
    a {
      color: ${(props) => props.color} !important;
    }
  }
  &#location {
    color: $ svg {
      path {
        fill: ${(props) => props.color} !important;
      }
    }
  }
`;
function Location(props) {
  const [showoptions, setShowoptions] = useState(true);
  function handleLocationClick() {
    props.dispatch(showLoader());
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log('Geolocation not supported');
      props.dispatch(hideLoader());
    }
  }
  function callHereApi(latitude, longitude) {
    const api = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${latitude}%2C${longitude}&lang=en-US&apiKey=ly9pWHjq6vF0cYs22NykDei60ZezcPnmEMPrcnLLOVs`;
    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        logMetrics('GeoLocator', 'called from hereApi', 'Info');
        props.dispatch(hideLoader());
        if (
          data &&
          data.items &&
          data.items[0].address &&
          data.items[0].address.houseNumber &&
          data.items[0].address.street &&
          data.items[0].address.postalCode &&
          data.items[0].address.stateCode &&
          data.items[0].address.countryCode &&
          data.items[0].address.countryCode === 'USA'
        ) {
          const house = data.items[0].address.houseNumber;
          const { street } = data.items[0].address;
          const zip = data.items[0].address.postalCode.split('-')[0];
          const { city } = data.items[0].address;
          const state = data.items[0].address.stateCode;

          props.speechonChange(`${house} ${street} ${city} ${state} ${zip}`);
        } else if (
          data &&
          data.items &&
          data.items[0].address &&
          data.items[0].address.building &&
          data.items[0].address.countryCode &&
          data.items[0].address.countryCode === 'USA'
        ) {
          props.speechonChange(data.items[0].address.building);
        } else {
          props.dispatch(setStreetGeoError(true));
          setTimeout(() => {
            props.speechonChange(`  `);
            props.dispatch({ type: 'UPDATE_ADDRESS_COLLECTION_RESET', value: [] });
          }, 2000);
        }
      })
      .catch((error2) => console.log(error2));
  }
  function invokeWirelessLocusApi(latitude, longitude) {
    const url = apiUrl().getStreetWithGeo;
    const data = {
      longitude,
      latitude,
    };
    const location =
      window.location.search &&
      (window.location.search.indexOf('testQualification') > -1 ||
        window.location.search.indexOf('nsa=y') > -1 ||
        window.location.search.indexOf('NSA=Y') > -1)
        ? 'Y'
        : null;
    let flowName;
    if (location && location === 'Y') {
      flowName = 'test|';
    }
    const requiredflowType = { flowType: flowName };
    return new Promise((resolve) => {
      callingApi(url, MethodType.POST, data, 'application/json', requiredflowType)
        .then((response) => {
          props.dispatch(hideLoader());
          const formattedAddress = response?.data?.addressInfo?.[0]?.standardizedAddress;
          if (
            formattedAddress &&
            formattedAddress.addressLine1 &&
            formattedAddress.city &&
            formattedAddress.stateProvince &&
            formattedAddress.postalCodeBase &&
            formattedAddress.iso3CountryCode &&
            formattedAddress.iso3CountryCode === 'USA'
          ) {
            props.speechonChange(
              `${formattedAddress.addressLine1} ${formattedAddress.city} ${formattedAddress.stateProvince} ${formattedAddress.postalCodeBase}`,
            );
            logMetrics('GeoLocator', 'called from locus', 'Info');
          } else {
            // callHereApi(latitude, longitude);
          }
          resolve(response);
        })
        .catch((error1) => {
          console.log(error1);
          // callHereApi(latitude, longitude);
          resolve(error1);
        });
    });
  }
  function success(position) {
    linkVzTag('location allowed');
    logMetrics('GeoLocator', 'allow', 'Info');
    props.speechonChange(`  `);
    props.dispatch({ type: 'UPDATE_ADDRESS_COLLECTION_RESET', value: [] });
    props.dispatch(showLoader());
    const { latitude } = position.coords;
    const { longitude } = position.coords;

    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    invokeWirelessLocusApi(latitude, longitude);
  }

  function error(e) {
    switch (e.code) {
      case e.PERMISSION_DENIED:
        window.sessionStorage.setItem('GeoQualBlock', 'Y');
        setShowoptions(false);
        linkVzTag('location blocked');
        logMetrics('GeoLocator', 'block', 'Info');
        break;
      case e.UNKNOWN_ERROR:
        setShowoptions(false);
        break;
      default:
    }
    console.log('Unable to retrieve your location');
    props.dispatch(hideLoader());
  }

  return (
    <div>
      {showoptions && (
        <GeoLocation
          id="location"
          color={props.colors?.color || '#000'}
          tabIndex="0"
          onKeyPress={() => handleLocationClick()}
          style={{ display: 'flex', float: 'left', right: '0px', top: '140px', gap: '3px', color: props?.colors?.color }}
        >
          <Link
            onClick={() => handleLocationClick()}
            onKeyPress={() => handleLocationClick()}
            linkText="Use my location"
            role="button"
            tabIndex="-1"
            style={{ marginBottom: '6px' }}
            surface={props.colors?.surface || (props.fromSticky ? 'dark' : 'light')}
            data-track={
              props.loopQualApp.homeLQ
                ? dataTrackwithDetail('link', 'use my location', 'lq banner')
                : props.fromSticky
                ? dataTrackwithDetail('link', 'use my location', 'lq sticky bar')
                : dataTrack('link', 'use my location')
            }
          />
          <GeoIcon
            id="location"
            className={props.colors?.surface || ''}
            // tabIndex="0"
            // role="button"
            // onClick={() => handleLocationClick()}
            onKeyPress={() => handleLocationClick()}
          >
            <Icon name="location" ariaHidden="true" color={props.colors?.color || (props.fromSticky ? '#FFFFFF' : '#000000')} size="small" />
          </GeoIcon>
        </GeoLocation>
      )}
    </div>
  );
}
Location.propTypes = {
  speechonChange: PropTypes.func,
  dispatch: PropTypes.func,
  fromSticky: PropTypes.bool,
  colors: PropTypes.any,
  loopQualApp: PropTypes.any,
};

export default Location;
